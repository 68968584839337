import React from 'react';
import Fade from 'react-reveal/Fade';
import dayforce from '../assets/dayforce.png';
import znotes from '../assets/znotes.png';
import yorku from '../assets/yorku.png';
import lab2client from '../assets/lab2client.svg';

const Bio = () => {
    return (
        <div className="Bio">
            <div className="bio" id="bio">
                <div className="wrapper">
                    <Fade><p><p style={{fontWeight: "bold"}}>About me</p>Hello! I'm currently in third year of my CS degree at York University. I am mostly exploring fields like Software Engineering and ML.</p></Fade>
                    <br/>
                    <Fade>
                        <p style={{fontWeight: "bold"}}>Work experience</p>
                        <p style={{marginTop: 35, borderLeftWidth: 3, borderColor: "#3067db", borderLeftStyle: "solid", paddingLeft: 10}}>
                            <strong>Software Engineer Intern @ <a href='https://dayforce.com'><img width={100} src={dayforce} /></a></strong> 
                            <br /> <span style={{fontSize: 13, fontStyle: "italic"}}>May 2024 - Present</span>z
                            <br /> <span>I am a part of the Absence Management team under the Workforce Management department. My area of work revolves around <span className='highlight'>test-automation</span>, <span className='highlight'>fullstack development</span> and large-scale <span className='highlight'>refactoring</span> using <span className='highlight'>C#</span>, <span className='highlight'>Selenium</span> and <span className='highlight'>.NET</span></span>
                        </p>
                        <p style={{marginTop: 35, borderLeftWidth: 3, borderColor: "#3067db", borderLeftStyle: "solid", paddingLeft: 10}}>
                            <strong>Research Assistant (Machine Vision) @ <a href='https://pixel.lab.yorku.ca/'><img width={100} src={yorku} /></a></strong> 
                            <br /> <span style={{fontSize: 13, fontStyle: "italic"}}>Sep 2023 - Apr 2024</span>
                            <br /> <span>Worked under the supervision of Dr. Reza Rizvi for the department of Mechanical Engineering at Lassonde. I contributed to the research and development of a <span className='highlight'>machine vision</span> project for <span className='highlight'>automating</span> creep-testing experiments using <span className='highlight'>Python</span></span>
                        </p>
                        <p style={{marginTop: 35, borderLeftWidth: 3, borderColor: "#3067db", borderLeftStyle: "solid", paddingLeft: 10}}>
                            <strong>Software Engineer Co-op @ <a href="https://lab2client.com/"><img width={120} src={lab2client} /></a></strong> 
                            <br /> <span style={{fontSize: 13, fontStyle: "italic"}}>May 2023 - Aug 2023</span>
                            <br /> <span>Led the engineering team (5 members) for the startup, employed under a dual-partnership between BEST Lassonde and Innovation Boost Zone. Delivered a <span className='highlight'>fullstack application</span> using <span className='highlight'>React</span> and <span className='highlight'>Node.js</span> that succesfully secured seed funding</span>
                        </p>
                        <p style={{marginTop: 35, borderLeftWidth: 3, borderColor: "#3067db", borderLeftStyle: "solid", paddingLeft: 10}}>
                            <strong>Software Engineer Intern @ <a href="https://znotes.org"><img width={150} src={znotes} /></a></strong> 
                            <br /> <span style={{fontSize: 13, fontStyle: "italic"}}>Sep 2021 - Aug 2022</span>
                            <br /> <span>Interned at my favorite highschool exam-revision website where I was in charge of developing and <span className='highlight'>optimising</span> major frontend areas using <span className='highlight'>React</span>. My contributions include the <span className='highlight'>Donation page</span> and <span className='highlight'>dynamic page titles</span> and metadata</span>
                        </p>
                    </Fade>
                </div>
            </div>
        </div>
    );
};

export default Bio;
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav } from 'react-bootstrap';

const Header = () => {
    return (
        <div className="Header">
            <div className='inner'>
                <Navbar className="navbar fixed-top" variant="dark" expand="lg">
                    <Navbar.Brand style={{color: "rgb(210, 210, 210)"}} href="#home">
                        Idrak Islam
                    </Navbar.Brand>
                    <Navbar.Toggle style={{fontSize: 12}} aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            <Nav.Link className="link" href="#bio"><span className="mono">01.</span> About me</Nav.Link>
                            {/* <Nav.Link className="link" href="#skills"><span className="mono">02.</span> Skills</Nav.Link> */}
                            <Nav.Link className="link" href="#work"><span className="mono">02.</span> Projects</Nav.Link>
                            <Nav.Link className="link" href="#footer"><span className="mono">03.</span> Get in touch</Nav.Link>
                        </Nav>
                        <div className="form-inline mt-2 mt-md-0">
                            <a target="_blank" rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/portfolio-1a5f4.appspot.com/o/resume.pdf?alt=media&token=f81b5d83-dba7-44f4-b1c4-ae02116400a9"><button className="btn btn-outline-light my-1 my-sm-0">Resume</button></a>
                            <a target="_blank" rel="noopener noreferrer" href="https://github.com/idrak888"><button className="btn btn-outline-light my-1 my-sm-0">Github</button></a>
                        </div>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        </div>
    );
};

export default Header;